<script lang="ts" setup>
import { defineEmits } from 'vue';
import type { AllBrands } from '~/data/entities';
import type { AllCollections } from '~/data/collections';
import { vElementVisibility } from '@vueuse/components';
import { useDebounceFn } from '@vueuse/core';
import { sortInitials } from './utils';

const { $eventClient } = useNuxtApp();

const emits = defineEmits(['updateOpen']);

const props = defineProps<{
	itemKey: string;
	pathPrefix: string;
	initialsGroup: string[];
	filteredItemsList: AllBrands[] | AllCollections[];
}>();

const mounted = ref<boolean>(false);
const visibleQueue = ref<string[]>([]);
const selectedGroup = inject<Ref<string>>('selectedGroup');
const setSelectedTab = inject('setSelectedTab') as (tab: string) => void;

const groupedList = (initial: string, key: string) => {
	const list = props.filteredItemsList!.filter((collection: any) =>
		collection[key].toLowerCase().startsWith(initial.toLowerCase()),
	);
	return list;
};

onMounted(() => {
	mounted.value = true;
});

const checkSticky = (state: boolean, initial: string) => {
	if (state && mounted.value === true) {
		visibleQueue.value.push(initial);
	} else if (state) {
		selectedGroup!.value = initial;
	}
};

const sortVisibleQueue = useDebounceFn(() => {
	visibleQueue.value = sortInitials(visibleQueue.value);
	if (visibleQueue.value[0] !== undefined) {
		selectedGroup!.value = visibleQueue.value[0];
	}
	mounted.value = false;
	visibleQueue.value = [];
}, 100);

watch(visibleQueue.value, () => {
	sortVisibleQueue();
});

const handleClick = (key: string, item: { name: any; handle: string }) => {
	emits('updateOpen');
	setSelectedTab('home');

	if (key === 'brands') {
		$eventClient.sendEvent('brand-click', {
			brandName: item.name,
			brandHandle: item.handle,
			from: window.location.href,
		});
	} else if (key === 'collections') {
		$eventClient.sendEvent('collection-click', {
			collectionName: item.name,
			collectionHandle: item.handle,
			from: window.location.href,
		});
	}
};
</script>
<template>
	<div
		class="py-3xl px-3xl mt-[148px] lg:mt-[106px] lg:pb-[200px] lg:h-[95vh] overflow-auto"
	>
		<div
			v-for="(initial, index) in initialsGroup"
			:id="initial"
			:key="index"
			:v-el="initial"
		>
			<div
				v-element-visibility="[
					(state: boolean) => checkSticky(state, initial),
					{ threshold: 1 },
				]"
				class="top-[10px] w-fit sticky bg-white"
				:class="{
					'!top-[10px]': index === 1 || index === 0,
				}"
			>
				<div
					class="w-9xl h-9xl flex items-center justify-center bg-gray-100 rounded"
				>
					<span class="text-xl font-semibold text-gray-800">
						{{ initial }}
					</span>
				</div>
			</div>
			<ul class="brand-list">
				<li
					v-for="(item, index2) in groupedList(initial, itemKey)"
					:key="index2"
					class="flex w-full"
				>
					<NuxtLink
						:to="`/${pathPrefix}/${item.handle.toLowerCase()}`"
						class="ml-5xl pl-8xl pr-3xl block w-full border-l-2 border-gray-100"
						@click.prevent="
							handleClick(pathPrefix, {
								name: item[itemKey as keyof typeof item],
								handle: item.handle,
							})
						"
					>
						<div class="py-3xl border-gray-200 border-b">
							<span
								class="hover:text-gray-800 text-base font-normal text-gray-800 transition duration-100 ease-in text-ellipsis line-clamp-1"
							>
								{{ item[itemKey as keyof typeof item] }}
							</span>
						</div>
					</NuxtLink>
				</li>
			</ul>
		</div>
	</div>
</template>
