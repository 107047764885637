<script setup lang="ts">
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@laam/ui/base';

import {
	PhHeartStraight,
	PhHouse,
	PhShootingStar,
	PhSquaresFour,
	PhStorefront,
	PhUserCircle,
} from '@phosphor-icons/vue';
import { useLoganStore } from '~/stores/logan';
import { HvcIcon } from '@laam/ui/icons';
import Logan from '~/components/logan/Logan.vue';
import LaamMobileHeader from '~/components/LaamMobileHeader.vue';
import HomepageSwitch from '~/components/HomepageSwitch.vue';
import TopBanner from '~/components/TopBanner.vue';
import type { DynamicConfig } from 'statsig-js';
import { useRoute } from 'vue-router';

const Categories = defineAsyncComponent(() => import('./Categories.vue'));
const Brands = defineAsyncComponent(() => import('./Brands.vue'));
const Account = defineAsyncComponent(() => import('./Account.vue'));
const AppDownloadBanner = defineAsyncComponent(
	() => import('../../AppDownloadBanner.vue'),
);

const { newArrivalPageExperiment } = useStatsigExperiment();

interface AppBannerConfig {
	ios: boolean;
	android: boolean;
}
const LoganStore = useLoganStore();
const route = useRoute();
const selectedTab = ref(route.path.includes('wishlist') ? 'wishlist' : 'home');
const { $eventClient } = useNuxtApp();

const isFromSavyour = computed(() => {
	if (route.query) {
		return route.query?.utm_source === 'savyour';
	}
	return false;
});

const appBannerTime = useCookie('app_download_banner');

const showAppBanner = ref<boolean>(false);

const appBannerConfig = ref();

const getAppBannerConfig = () => {
	const config: DynamicConfig | undefined = window?.$statsig?.getConfig(
		'app_download_banner',
	);
	if (!config?.value) {
		throw new Error('app_download_banner config is not defined');
	}
	appBannerConfig.value = config?.value as unknown as AppBannerConfig;
};

useWishlistStore();
function tabIconHighlighted(tab: string) {
	if (tab === 'home') {
		if (route.name === 'identifier') return 'fill';
		else return 'regular';
	} else if (tab === 'wishlist') {
		if (route.name === 'wishlist') return 'fill';
		else return 'regular';
	} else return selectedTab.value === tab ? 'fill' : 'regular';
}
const setSelectedTab = (tab: string) => {
	selectedTab.value = tab;
};

const computeShowAppBanner = () => {
	const userAgent = navigator.userAgent;
	const isApp = localStorage.getItem('isApp') ? true : false;
	if (userAgent) {
		const isIOS = /iPhone|iPad|iPod/i.test(userAgent);
		const isAndroid = /Android/i.test(userAgent);

		if (isFromSavyour.value) {
			showAppBanner.value = false;
			return;
		}

		if (isIOS && !isApp && appBannerConfig.value.ios) {
			showAppBanner.value = true;
			return;
		}
		if (isAndroid && !isApp && appBannerConfig.value.android) {
			showAppBanner.value = true;
			return;
		}
		showAppBanner.value = false;
		return;
	}

	showAppBanner.value = false;
	return;
};

provide('setSelectedTab', setSelectedTab);
const visible = inject('headerVisible') as Ref<boolean>;
const logEvent = (tab: string) => {
	$eventClient.sendEvent('navbar-click', {
		tabName: tab,
		from: window.location.href,
	});
};

onMounted(() => {
	callWithRetries(() => getAppBannerConfig());
	const campusVerified = route.query.campus_verified;
	if (appBannerTime.value !== 'closed' && !campusVerified) {
		computeShowAppBanner();
	}
});

const allowAppBanner = computed(() => {
	const utms = sessionStorage.getItem('campaign');
	if (!utms) {
		return true;
	}
	const params = new URLSearchParams(utms);
	if (
		params.get('utm_medium') === 'referral' &&
		params.get('utm_source') === 'simosa'
	) {
		return false;
	}
	return true;
});
</script>

<template>
	<div class="lg:hidden">
		<ClientOnly>
			<AppDownloadBanner v-if="showAppBanner && allowAppBanner" />
		</ClientOnly>
		<Logan />
		<Tabs v-model:modelValue="selectedTab" class="relative text-gray-800">
			<TabsList
				class="lg:hidden fixed bottom-0 left-0 right-0 z-10 grid grid-cols-5 transition-all bg-white border-t"
				:class="{
					'bottom-[-64px]': !visible,
				}"
			>
				<TabsTrigger
					class="nav-button"
					value="home"
					@click="
						() => {
							$router.push('/');
							logEvent('home');
						}
					"
				>
					<PhHouse size="24" :weight="tabIconHighlighted('home')" />
					<span class="nav-button-text">Home</span>
				</TabsTrigger>
				<TabsTrigger
					class="nav-button bottom-nav__categories--button"
					value="category"
					@click="logEvent('category')"
				>
					<PhSquaresFour size="24" :weight="tabIconHighlighted('category')" />
					<span class="nav-button-text">Categories</span>
				</TabsTrigger>
				<TabsTrigger
					class="nav-button bottom-nav__brands--button"
					value="brands"
					@click="logEvent('brands')"
				>
					<PhStorefront size="24" :weight="tabIconHighlighted('brands')" />
					<span class="nav-button-text">Brands</span>
				</TabsTrigger>

				<TabsTrigger
					v-if="newArrivalPageExperiment?.is_active"
					class="nav-button bottom-nav__new-arrivals--button"
					value="new-arrivals"
					@click="
						() => {
							$router.push({ name: 'new-arrivals' });
							logEvent('new-arrivals');
						}
					"
				>
					<PhShootingStar
						size="24"
						:weight="tabIconHighlighted('new-arrivals')"
					/>
					<span class="nav-button-text">New Arrivals</span>
				</TabsTrigger>
				<TabsTrigger
					v-else
					class="nav-button bottom-nav__wishlist--button"
					value="wishlist"
					@click="
						() => {
							$router.push({ name: 'wishlist' });
							logEvent('wishlist');
						}
					"
				>
					<PhHeartStraight size="24" :weight="tabIconHighlighted('wishlist')" />
					<span class="nav-button-text">Wishlist</span>
				</TabsTrigger>
				<TabsTrigger
					class="py-md flex flex-col items-center !text-xxs font-medium gap-xxs border-0 accounts_tab"
					value="account"
					@click="logEvent('account')"
				>
					<div class="relative">
						<PhUserCircle
							size="24"
							:weight="tabIconHighlighted('account')"
							class="z-[1]"
						/>
						<HvcIcon
							v-if="LoganStore.isHvc"
							class="absolute left-2xl top-xl z-[2]"
						/>
					</div>
					<span>Account</span>
				</TabsTrigger>
			</TabsList>

			<Transition name="fade">
				<TabsContent v-if="selectedTab === 'home'" value="home">
					<div class="relative">
						<ClientOnly>
							<TopBanner />
						</ClientOnly>
						<LaamMobileHeader />
						<HomepageSwitch />

						<slot />
					</div>
				</TabsContent>
			</Transition>
			<Transition name="fade">
				<TabsContent
					v-if="selectedTab === 'category'"
					value="category"
					class="w-[50vw]"
				>
					<Categories />
				</TabsContent>
			</Transition>
			<Transition name="fade">
				<TabsContent v-if="selectedTab === 'brands'" value="brands">
					<Brands />
				</TabsContent>
			</Transition>
			<Transition name="fade">
				<TabsContent
					v-if="
						newArrivalPageExperiment?.is_active &&
						selectedTab === 'new-arrivals'
					"
					value="new-arrivals"
				>
					<div class="relative">
						<ClientOnly>
							<TopBanner />
						</ClientOnly>
						<LaamMobileHeader />
						<HomepageSwitch />
						<slot />
					</div>
				</TabsContent>
			</Transition>
			<Transition name="fade">
				<TabsContent v-if="selectedTab === 'wishlist'" value="wishlist">
					<slot />
				</TabsContent>
			</Transition>
			<Transition name="fade">
				<TabsContent v-if="selectedTab === 'account'" value="account">
					<Account />
				</TabsContent>
			</Transition>
		</Tabs>
	</div>
</template>

<style scoped>
.nav-button {
	@apply py-xl px-xl w-full gap-xxs border-0 flex flex-col items-center justify-center;
}

.nav-button-text {
	@apply font-medium !text-xxs text-center capitalize text-nowrap;
}
.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
	opacity: 0;
}
</style>
