<script setup lang="ts">
import { useWindowScroll, watchDebounced, watchThrottled } from '@vueuse/core';
import LazyImage from '../LazyImage.vue';
const { $cathodeClient, $eventClient } = useNuxtApp();
import { vElementVisibility } from '@vueuse/components';

interface CategoryCardProps {
	redirect: string;
	horizontalIndex: number;
	verticalIndex: number;
	data: {
		id?: number;
		image: string;
		logo?: string;
		node_level?: number;
		parent_id?: number;
		product_count?: number;
		title: string;
		valhalla_score?: number;
	};
	dataSource: {
		dataSource: string;
		version: string | undefined;
		clickedFrom: string;
	};
	parentTitle: string;
}

const props = defineProps<CategoryCardProps>();

const { y } = useWindowScroll();
const hasWindowScrolled = ref(false);
watchThrottled(
	y,
	() => {
		hasWindowScrolled.value = true;
	},
	{ throttle: 200 },
);

function handleClickEvent() {
	$cathodeClient.queueEvent('entity_click', {
		source: window.location.href,
		page: 'homepage',
		href: props.redirect,
		clicked_from: 'category-listing',
		image: props.data.image,
		title: props.data.title,
		entity: 'node',
		horizontal_index: props.horizontalIndex,
		vertical_index: props.verticalIndex,
		slotted: false,
		heading: props.data.title,
		component: 'categories',
		product_count: props.data.product_count || null,
		sale_percentage: 0,
		scroll_position: y.value,
		data_source: `${props.dataSource.dataSource} - ${props.dataSource.version}`,
		data_string: JSON.stringify(props.data),
	});
	// category section in navbar click events
	$eventClient.sendEvent('category-clicked', {
		source: window.location.href,
		categoryLink: props.redirect,
		categoryName: props.data.title,
	});
}

const isVisible = ref(false);
function onElementVisibility(state: boolean) {
	isVisible.value = state;
}
watchDebounced(
	isVisible,
	(value) => {
		if (value) {
			$cathodeClient.queueImpression('entity_impression', {
				data: [
					{
						href: props.redirect,
						product_count: props.data.product_count || null,
						sale_percentage: 0,
						slotted: false,
						horizontal_index: props.horizontalIndex,
						data_string: JSON.stringify(props.data),
						image: props.data.image,
						title: props.data.title,
					},
				],
				method: 'static',
				vertical_index: props.verticalIndex,
				entity: 'node',
				heading: '',
				component: 'category-listing',
				scroll_position: y.value,
				source: window.location.href,
				type: hasWindowScrolled.value ? 'scroll_impression' : 'load_impression',
				time: new Date().toUTCString(),
				data_source: `${props.dataSource.dataSource} - ${props.dataSource.version}`,
				clicked_from: '',
				page: 'category-listing',
			});
		}
	},
	{
		debounce: useRuntimeConfig().public.impressionDebounceTime,
	},
);
</script>

<template>
	<NuxtLink :to="redirect" @click="handleClickEvent()">
		<div
			v-element-visibility="[onElementVisibility, { threshold: 0.8 }]"
			class="category-card gap-md flex flex-col"
		>
			<div
				class="rounded-xs image-box relative !min-h-none aspect-square text-gray-800"
			>
				<LazyImage
					v-if="data.logo !== 'nan'"
					:width="isMobileView() ? 150 : 240"
					:src="data.logo || data.image || ''"
					:alt="data.title || ''"
					class="object-cover object-top"
					loading="lazy"
				/>
			</div>
			<span
				class="block w-full text-xs font-medium text-center text-gray-800"
				data-testid="categoryTitle"
			>
				{{ data.title }}
			</span>
		</div>
	</NuxtLink>
</template>
