<script setup lang="ts">
import { Input, SingleSelectList, SingleSelectListItem } from '@laam/ui/base';
import { PhMagnifyingGlass, PhX } from '@phosphor-icons/vue';

const emits = defineEmits(['update:selectedGroup']);
const emitUpdate = (value: string) => {
	emits('update:selectedGroup', value);
};

defineProps<{
	pathPrefix: string;
	initialsGroup: string[];
}>();

const searchQuery = inject<Ref<string>>('searchQuery');
const selectedGroup = inject<Ref<string>>('selectedGroup', ref(''));
const setSelectedTab = inject('setSelectedTab') as (tab: string) => void;

// Function to scroll selected item into view
const scrollToSelected = () => {
	nextTick(() => {
		const selectedElement = document.getElementById(
			`single-select-${selectedGroup.value}`,
		);
		if (selectedElement) {
			selectedElement.scrollIntoView({
				behavior: 'smooth',
				block: 'nearest',
				inline: 'center',
			});
		}
	});
};

watch(selectedGroup, () => {
	scrollToSelected();
});

onMounted(() => {
	scrollToSelected();
});
</script>

<template>
	<div
		class="brand-list-header lg:left-3xl lg:right-3xl lg:border-b-0 mt fixed top-0 left-0 right-0 z-10 transition-all bg-white border-b"
	>
		<div class="py-lg pl-3xl lg:pl-0 lg:pr-0 pr-md gap-3xl flex text-gray-800">
			<Input
				v-model="searchQuery"
				size="sm"
				class="grow"
				:placeholder="`Search for ${pathPrefix}`"
			>
				<template #leftIcon>
					<PhMagnifyingGlass size="20" />
				</template>
				<template v-if="searchQuery" #rightIcon>
					<PhX size="20" @click="searchQuery = ''" />
				</template>
			</Input>

			<CartButton v-if="isMobileView()" @click="setSelectedTab('home')" />
		</div>
		<slot />
		<SingleSelectList
			class="brand-list-initials gap-md lg:py-md lg:px-0 px-3xl py-md w-fit lg:!w-full flex flex-row max-w-full overflow-auto"
			default-value="recommended"
			:model-value="selectedGroup"
			@update:model-value="emitUpdate"
		>
			<SingleSelectListItem
				v-for="initial in initialsGroup"
				:id="`single-select-${initial}`"
				:key="initial"
				:value="initial"
				class="flex flex-col items-center rounded-md h-8xl w-8xl !p-sm aspect-square text-gray-800 bg-white data-[state=checked]:text-white data-[state=checked]:bg-gray-800 text-sm font-semibold"
			>
				{{ initial }}
			</SingleSelectListItem>
		</SingleSelectList>
	</div>
</template>
