import type { CategoryTreeResponse } from '@laam/cms-shared';

export const getChildLessL3 = (
	categories: CategoryTreeResponse[] | undefined,
) => {
	const nodes: Record<string, CategoryTreeResponse[]> = {};

	if (!categories) {
		return nodes;
	}

	categories.forEach((l1Node) => {
		l1Node?.children?.forEach((l2Node) => {
			l2Node?.children?.forEach((l3Node) => {
				if (!l3Node.children) {
					const key = l1Node.title + l2Node.title;

					// Ensure the key exists before pushing
					if (!nodes[key]) {
						nodes[key] = [];
					}

					nodes[key].push(l3Node);
				}
			});
		});
	});

	return nodes;
};
