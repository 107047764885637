<script lang="ts">
export type BannerReasons =
	| 'free-shipping'
	| 'campus-email-verification'
	| 'campus-doc-verification';
</script>

<script setup lang="ts">
import { useElementSize } from '@vueuse/core';
import ShippingAlert from './ShippingAlert.vue';
import type { ShippingConfig } from '~/types/statsig';

const shippingConfig = inject('shippingConfig') as Ref<ShippingConfig>;

const headerTop = ref();
const announcements = ref();

const headerTopSize = computed(() => {
	return useElementSize(headerTop);
});

const textSize = computed(() => {
	return useElementSize(announcements);
});

const isScroll = computed(() => {
	return textSize.value.width.value > headerTopSize.value.width.value;
});

const topBannerReason = inject('topBannerReason') as Ref<
	BannerReasons | undefined
>;

const bannerBody = computed<Component | string>(() => {
	if (topBannerReason.value === 'free-shipping') {
		return ShippingAlert;
	} else if (topBannerReason.value === 'campus-email-verification') {
		return 'Welcome to LAAM! Please verify your email ID to complete your application for LAAM Campus Program.';
	} else if (topBannerReason.value === 'campus-doc-verification') {
		return 'Your application for LAAM Campus Program is currently under review.';
	} else throw new Error('Invalid banner reason');
});
</script>

<template>
	<div
		v-if="!!topBannerReason && shippingConfig !== null"
		class="sticky top-0 z-50"
	>
		<!-- <div v-if="!!true" class="sticky top-0 z-20"> -->
		<div
			id="top_banner"
			ref="headerTop"
			class="marquee h-7xl whitespace-nowrap flex items-center justify-center text-white bg-gray-900"
		>
			<div
				ref="announcements"
				class="font-inter md:text-sm gap-md flex items-center text-xs text-white"
				:class="{ 'banner-scroll ': isScroll }"
			>
				<template v-if="typeof bannerBody === 'string'">
					{{ bannerBody }}
				</template>
				<template v-else>
					<component :is="bannerBody" />
				</template>
			</div>
		</div>
	</div>
</template>
